import React from "react"
import styled from "styled-components";
import Icon from '@mdi/react';
import { mdiPhone, mdiInstagram, mdiEmail, mdiMapMarker } from '@mdi/js';
import { DDD, PGreen, PGreenHref } from "./common";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  @media (max-width: 600px) { 
    width: 100%;
    max-width: 90vw;
  }
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2.5px 0;
  padding: 2.5px 0;
  align-items: center;
  @media (max-width: 600px) { 
    font-size: 0.9em;
  }
`

const Contact = () => {

  return (
    <Container>
      <Line>
        <Icon path={mdiPhone} size={"1em"} color="rgb(19, 121, 136)" style={{ marginRight: "5px" }}/>        
        <PGreenHref href="tel:34991232941" title="Valléria"><DDD>34&nbsp;</DDD> 99123-2941</PGreenHref>
      </Line>
      <Line>
        <Icon path={mdiEmail} size={"1em"} color="rgb(19, 121, 136)" style={{ marginRight: "5px" }}/>

        <PGreenHref href="mailto:contato@nucleojardimdaconsciencia.com.br">
          contato@nucleojardimdaconsciencia.com.br
        </PGreenHref>
      </Line>
      <Line>
        <Icon path={mdiInstagram} size={"1em"} color="rgb(19, 121, 136)" style={{ marginRight: "5px" }}/>

        <PGreenHref href="https://www.instagram.com/nucleojardimdaconsciencia/" rel="noreferrer noopener">
          nucleojardimdaconsciencia
        </PGreenHref>
      </Line>
      <Line>
        <Icon path={mdiMapMarker} size={"1em"} color="rgb(19, 121, 136)" style={{ marginRight: "5px" }}/>

        <PGreenHref href="https://goo.gl/maps/wipG1Mf82Ndsi6Kf9/" rel="noreferrer noopener">
          Av. Princesa Isabel, 590 - 1º Andar <br/>
          Fundinho - Uberlândia/MG - 38400-192
        </PGreenHref>
      </Line>
    </ Container>
  )
}

export default Contact;